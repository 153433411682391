<template>
  <b-row>
    <b-col
      cols="12"
    >
      <b-card no-body>
        <b-card-header>
          <b-card-title>Teklif Bekleyen Projeler</b-card-title>
        </b-card-header>
        <b-table
          v-if="projects.length > 0"
          responsive="sm"
          :fields="fields"
          :items="projects"
          striped
        >
          <template #cell(title)="data">
            {{ data.item.title }}
            <div v-if="data.item.pnumber">
              <small class="text-primary">{{ data.item.pnumber }}</small>
            </div>
          </template>
          <template #cell(customers)="data">
            {{ data.item.customers }}
            <div v-if="data.item.cnumber">
              <small class="text-primary">{{ data.item.cnumber }}</small>
            </div>
          </template>
          <template #cell(control)="data">
            <b-button
              size="sm"
              variant="success"
              :to="'/app/offers/add/' + data.item.id"
              class="text-nowrap"
            >
              <FeatherIcon icon="PlusIcon" />
              Oluştur
            </b-button>
          </template>
        </b-table>
        <b-card-footer v-else>
          <b-alert show>
            <div class="alert-body text-center">
              <div class="mb-1">
                Teklif bekleyen proje bulunamadı!
              </div>
              <b-button
                variant="info"
                size="sm"
                :to="'/app/projects/add/' + this.$route.params.id_customers"
              >
                Proje Talebi Oluştur
              </b-button>
            </div>
          </b-alert>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BTable, BAlert, BCardHeader, BCardTitle, BCardFooter,
} from 'bootstrap-vue'

export default {
  name: 'ProjectSelect',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BAlert,
    BCardHeader,
    BCardTitle,
    BCardFooter,
  },
  data() {
    return {
      keyword: '',
      fields: [
        {
          key: 'title',
          label: 'Proje',
        },
        {
          key: 'customers',
          label: 'Müşteri',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'text-right',
        },
      ],
    }
  },
  computed: {
    projects() {
      return this.$store.getters['projects/dataList']
    },
  },
  created() {
    this.getProjects()
  },
  methods: {
    getProjects() {
      const params = {
        select: [
          'projects.id AS id',
          'projects.title as title',
          'customers.company AS customers',
          'customers.cnumber AS cnumber',
          'CONCAT(customers.cnumber,"-P",projects.pnumber) AS pnumber',
        ],
        where: {
          'projects.id_project_statuses': 9,
          'projects.id_customers': this.$route.params.id_customers,
        },
        start: 0,
        limit: 10,
      }
      this.$store.dispatch('projects/getDataList', params)
    },
  },
}
</script>
